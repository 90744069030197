import { defineStore, acceptHMRUpdate } from 'pinia';
import { useFetch } from '#imports';

export const useArticlesStore = defineStore('ArticlesStore', {
  state: () => ({
    articles: [] as Array<any>,
    isLoading: false,
    currentPage: 1,
    totalPages: 1,
    hideLoadMore: false,
  }),

  actions: {
    async fetchArticles() {
      if (this.currentPage > this.totalPages) {
        // If the current page exceeds total pages, stop fetching
        this.hideLoadMore = true;
        return;
      }

      this.isLoading = true;
      try {
        const articlesData = await $fetch(`/api/fetchArticles`, {
          params: {
            categoryKey: 'articles',
            per_page: 6,
            page: this.currentPage,
          },
        });

        const articles = articlesData?.data ?? [];
        const totalPages = articlesData?.totalPages ?? 1;

        this.addArticles(articles);

        // Update totalPages and hideLoadMore accordingly
        this.totalPages = totalPages;
        this.hideLoadMore = this.currentPage >= this.totalPages;
      } catch (error) {
        this.handleError('Error fetching articles:', error);
      } finally {
        this.isLoading = false;
      }
    },

    async loadMoreArticles() {
      this.currentPage++;
      await this.fetchArticles();
    },

    addArticles(newArticles: Array<any>) {
      this.articles = [...this.articles, ...newArticles];
    },

    handleError(message: string, error: any) {
      console.error(message, error);
    },
  },

  getters: {
    getArticles: (state) => state.articles,
  },
});

// HMR support
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useArticlesStore, import.meta.hot));
}
